import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ADD_COACH_POST,
  COACH_DELETE,
  COACH_DETAILS_GET,
  COACH_LIST_GET,
  COACH_RESEND_ACTIVATION_CODE,
  COACH_TEAM_LIST_GET,
  COACH_UPDATE_STATUS_PUT,
  GET_CATEGORIES,
  PROGRESS_GET,
  PROGRESS_LIST_GET,
  UNVERIFIED_COACH_LIST_GET,
  WATCH_LIST_GET
} from 'constants/api-endpoints';
import { ApiResponse, ApiState, PaginatedQuery, PaginatedResponse } from 'interfaces/api.interface';
import { RootState } from 'store';
import { setLoading } from 'store/global.slice';
import { FormUtils } from 'utils/form.utils';
import { http } from 'utils/http.service';
import { Coach, CoachBasicInfo } from './coach-management.interface';
import { COACH_STATUS } from 'enums/enums';
import { toastService } from 'utils/toast.service';
import { CoachData } from './all-coaches/add-coach/add-coach.validator';
import { Team } from 'modules/team-management/team-management.interaface';
import { Player } from 'modules/player-management/player-management.interface';
import { ParentCategory, ProgressList, ProgressStats } from 'interfaces/progress';

interface CoachManagement {
  allCoaches: Array<Coach>;
  selectedCoach: Coach | null;
  unverifiedCoaches: Array<CoachBasicInfo>;
  status: ApiState;
  error: string | null;
  total: number;
  details: Coach | null;
  coachTeams: {
    teams: Array<Team>;
    status: ApiState;
    error: string | null;
    total: number;
  };
  coachStats: ProgressStats | null;
  progressList: {
    total: number;
    status: ApiState;
    error: string | null;
    data: Array<ProgressList> | null;
    categories: ParentCategory[];
  };
  watchList: {
    players: Array<Player>;
    status: ApiState;
    error: string | null;
    total: number;
  };
}

export const coachManagementSlice = createSlice({
  name: 'coachManagement',
  initialState: {
    allCoaches: [],
    unverifiedCoaches: [],
    selectedCoach: null,
    total: 0,
    error: null,
    status: 'idle',
    details: null,
    coachTeams: {
      teams: [],
      total: 0,
      error: null,
      status: 'idle'
    },
    watchList: {
      players: [],
      total: 0,
      error: null,
      status: 'idle'
    },
    progressList: {
      categories: [],
      total: 0,
      status: 'idle',
      error: null,
      data: []
    },
    coachStats: null
  } as CoachManagement,
  reducers: {
    setDetails: (state, { payload }: { payload: Coach | null }) => {
      state.details = payload;
    },
    setCoachStats: (state, { payload }: { payload: ProgressStats | null }) => {
      state.coachStats = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllCoaches.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllCoaches.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.allCoaches = [...action.payload.data.data])
          : (state.allCoaches = []);

        action.payload.data && action.payload.data.total
          ? (state.total = action.payload?.data?.total)
          : (state.total = 0);
      })
      .addCase(getAllCoaches.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message as string;
      })
      .addCase(getUnverifiedCoaches.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUnverifiedCoaches.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.unverifiedCoaches = [...action.payload.data.data])
          : (state.unverifiedCoaches = []);

        action.payload.data && action.payload.data.total
          ? (state.total = action.payload?.data?.total)
          : (state.total = 0);
      })
      .addCase(getUnverifiedCoaches.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message as string;
      })
      .addCase(getCoachDetail.fulfilled, (state, action) => {
        state.details = action.payload.data;
      })
      .addCase(getCoachTeams.fulfilled, (state, action) => {
        state.coachTeams.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.coachTeams.teams = [...action.payload.data.data])
          : (state.coachTeams.teams = []);

        action.payload.data && action.payload.data.total
          ? (state.coachTeams.total = action.payload?.data?.total)
          : (state.coachTeams.total = 0);
      })
      .addCase(getCoachWatchList.pending, (state) => {
        state.watchList.status = 'loading';
      })
      .addCase(getCoachWatchList.fulfilled, (state, action) => {
        state.watchList.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.watchList.players = [...action.payload.data.data])
          : (state.watchList.players = []);

        action.payload.data && action.payload.data.total
          ? (state.watchList.total = action.payload?.data?.total)
          : (state.watchList.total = 0);
      })
      .addCase(getCoachWatchList.rejected, (state, action) => {
        state.watchList.status = 'failed';
        state.watchList.error = action.error.message as string;
      })
      .addCase(getCoachProgressStats.fulfilled, (state, action) => {
        state.coachStats = action.payload.data;
      })
      .addCase(getCoachProgressList.pending, (state) => {
        state.progressList.status = 'loading';
      })
      .addCase(getCoachProgressList.fulfilled, (state, action) => {
        state.progressList.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.progressList.data = [...action.payload.data.data])
          : (state.progressList.data = []);

        action.payload.data && action.payload.data.total
          ? (state.progressList.total = action.payload?.data?.total)
          : (state.progressList.total = 0);
      })
      .addCase(getCoachProgressList.rejected, (state, action) => {
        state.progressList.status = 'failed';
        state.progressList.error = action.error.message as string;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.data.length
          ? (state.progressList.categories = [...action.payload.data])
          : (state.progressList.categories = []);
      });
  }
});

export const getAllCoaches = createAsyncThunk(
  'coach/all-coach-list',
  async (query: PaginatedQuery, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...query, ...query.filter };
      delete newQuery.filter;
      const response = await http.get<ApiResponse<PaginatedResponse<Coach>>>(COACH_LIST_GET, FormUtils.parse(newQuery));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getUnverifiedCoaches = createAsyncThunk(
  'coach/unverified-coach-list',
  async (query: PaginatedQuery, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...query, ...query.filter };
      delete newQuery.filter;
      const response = await http.get<ApiResponse<PaginatedResponse<CoachBasicInfo>>>(
        UNVERIFIED_COACH_LIST_GET,
        FormUtils.parse(newQuery)
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getCoachDetail = createAsyncThunk(
  'coach/details',
  async (query: { id: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.get<ApiResponse<Coach>>(COACH_DETAILS_GET(query.id));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getCoachTeams = createAsyncThunk(
  'coach/teams',
  async (query: { coachId: string; pageOptions: PaginatedQuery }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.get<ApiResponse<PaginatedResponse<Team>>>(
        COACH_TEAM_LIST_GET(query.coachId),
        FormUtils.parse(query.pageOptions)
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateCoachStatus = createAsyncThunk(
  'coach/updateStatus',
  async (payload: { id: string; status: COACH_STATUS }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.put<ApiResponse<any>>(COACH_UPDATE_STATUS_PUT(payload.id), {
        status: payload.status
      });
      toastService.showToast(response.data.message, 'success');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addCoach = createAsyncThunk(
  'coach/add-coach',
  async (payload: { coaches: CoachData[] }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.post<ApiResponse<any>>(ADD_COACH_POST, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const sendCodeToCoach = createAsyncThunk(
  'coach/send-activation-code',
  async (payload: { email: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.post<ApiResponse<any>>(COACH_RESEND_ACTIVATION_CODE, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const removeCoach = createAsyncThunk(
  'coach/remove-coach',
  async (payload: { id: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.delete<ApiResponse<any>>(COACH_DELETE(payload.id));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getCoachWatchList = createAsyncThunk(
  'coach/watchlist',
  async ({ query, coachId }: { query: PaginatedQuery; coachId: string }, { rejectWithValue, dispatch }) => {
    try {
      const newQuery = { ...query, ...query.filter };
      delete newQuery.filter;
      const response = await http.get<ApiResponse<PaginatedResponse<Player>>>(
        WATCH_LIST_GET(coachId),
        FormUtils.parse(newQuery)
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getCoachProgressStats = createAsyncThunk(
  'coach-management/progress-stats',
  async (query: { coachId: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.get<ApiResponse<ProgressStats>>(PROGRESS_GET(query.coachId));
      if (!response?.data?.data?.averageRating || response?.data?.data?.averageRating < 0) {
        response.data.data['averageRating'] = 0;
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getCoachProgressList = createAsyncThunk(
  'coach-management/progress-list',
  async (payload: { coachId: string; query: PaginatedQuery }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...payload.query, ...payload.query.filter };
      delete newQuery.filter;
      const response = await http.get<ApiResponse<PaginatedResponse<any>>>(
        PROGRESS_LIST_GET(payload.coachId),
        FormUtils.parse(newQuery)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getCategories = createAsyncThunk(
  'coach-management/progress-list-categories',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.get<ApiResponse<Array<ParentCategory>>>(GET_CATEGORIES);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const selectCoaches = (state: RootState) => state.coachManagement;

export const { setDetails, setCoachStats } = coachManagementSlice.actions;

export default coachManagementSlice.reducer;
