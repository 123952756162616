export const modalStyles = {
  mainContainer: {
    top: '50%',
    width: 'fit-content',
    left: '50%',
    boxShadow: 24,
    height: 'auto',
    borderRadius: '12px',
    position: 'absolute',
    bgcolor: 'background.paper',
    border: '1px solid lightGray',
    transform: 'translate(-50%, -50%)'
  },
  heading: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    padding: '0px 10px 0px 10px',
    borderBottom: '1px solid lightGray'
  },

  modal: {
    padding: '20px',
    display: 'flex',
    rowGap: '20px',
    alignItems: 'center',
    justifyItems: 'center',
    flexDirection: 'column'
  },

  listItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    width: '100%'
  },

  listItemLeft: {
    width: '50%',
    textAlign: 'right'
  },
  listItemRight: {
    width: '50%',
    textAlign: 'left'
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    gap: '15px'
  }
};
