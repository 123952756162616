import { Box, Modal, Typography } from '@mui/material';
import ContainedButton from 'components/button/contained-button';
import { modalStyles } from './style';
import { Institute } from 'interfaces/institute';
import { titleCase } from 'utils/globalHelper';
import { SUBSCRIPTION_STATUS, subscriptionStatus } from 'enums/enums';
import { useAppDispatch } from 'store';
import { logout } from 'modules/auth/auth.slice';

type Props = {
  isOpen: boolean;
  profileDetails: Institute;
  handleProceedForPayment: () => void;
  subscriptionStatus: subscriptionStatus;
};

const PurchaseSubscriptionModal = ({ isOpen, profileDetails, handleProceedForPayment, subscriptionStatus }: Props) => {
  {
    const dispatch = useAppDispatch();
    const handleSubscriptionCancelled = () => {
      localStorage.clear();
      location.reload();
    };
    const handleLogout = () => {
      dispatch(
        logout(() => {
          localStorage.clear();
          window.location.reload();
        })
      );
    };

    return subscriptionStatus === SUBSCRIPTION_STATUS.PENDING || subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED ? (
      <Modal open={isOpen} disableAutoFocus>
        <Box sx={{ ...modalStyles.mainContainer, width: '600px' }}>
          <Box sx={modalStyles.heading}>
            {subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED && (
              <Typography variant="h3" component="h3">
                Renew Subscription
              </Typography>
            )}
            {subscriptionStatus === SUBSCRIPTION_STATUS.PENDING && (
              <Typography variant="h3" component="h3">
                Subscription Details
              </Typography>
            )}
          </Box>

          <Box sx={{ ...modalStyles.modal, width: '100%', height: '100%' }}>
            {subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED && (
              <Typography variant="h4">Your subscription has been expired, please renew your subscription</Typography>
            )}
            <Box sx={{ ...modalStyles.listItemWrapper }}>
              <Typography sx={{ ...modalStyles.listItemLeft }} variant="h4">
                Institute Name:
              </Typography>
              <Typography sx={{ ...modalStyles.listItemRight }}>
                {profileDetails.name ? titleCase(profileDetails.name) : '-'}
              </Typography>
            </Box>
            <Box sx={{ ...modalStyles.listItemWrapper }}>
              <Typography sx={{ ...modalStyles.listItemLeft }} variant="h4">
                Institute Type:
              </Typography>
              <Typography sx={{ ...modalStyles.listItemRight }}>
                {profileDetails?.instituteType ? profileDetails?.instituteType.split('_').join(' ') : '-'}
              </Typography>
            </Box>
            <Box sx={{ ...modalStyles.listItemWrapper }}>
              <Typography variant="h4" sx={{ ...modalStyles.listItemLeft }}>
                Subscription Type:
              </Typography>
              <Typography sx={{ ...modalStyles.listItemRight }}>
                {profileDetails?.subscriptionType ? profileDetails.subscriptionType : '-'}
              </Typography>
            </Box>
            <Box sx={{ ...modalStyles.listItemWrapper }}>
              <Typography variant="h4" sx={{ ...modalStyles.listItemLeft }}>
                Subscription Cost:
              </Typography>
              <Typography sx={{ ...modalStyles.listItemRight }}>
                {profileDetails.subscriptionPrice ? '$' + profileDetails.subscriptionPrice : '-'}
              </Typography>
            </Box>
          </Box>
          <Box sx={modalStyles.buttonContainer}>
            <ContainedButton
              variant="outlined"
              style={{ minWidth: '140px' }}
              title="Logout"
              onClick={() => handleLogout()}
            />
            <ContainedButton
              style={{ minWidth: '140px' }}
              title="Proceed To Payment"
              onClick={() => handleProceedForPayment()}
            />
          </Box>
        </Box>
      </Modal>
    ) : (
      <Modal open={isOpen} disableAutoFocus>
        <Box sx={{ ...modalStyles.mainContainer, width: '600px' }}>
          <Box sx={modalStyles.heading}>
            <Typography variant="h3" component="h3">
              Subscription Cancelled
            </Typography>
          </Box>

          <Box sx={{ ...modalStyles.modal, width: '100%', height: '100%' }}>
            <Typography variant="h4">Your subscription has been cancelled.</Typography>
          </Box>
          <Box sx={modalStyles.buttonContainer}>
            <ContainedButton
              fullWidth
              style={{ minWidth: '140px' }}
              title="ok"
              onClick={() => handleSubscriptionCancelled()}
            />
          </Box>
        </Box>
      </Modal>
    );
  }
};

export default PurchaseSubscriptionModal;
