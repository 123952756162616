import { ThunkDispatch, combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from 'modules/auth/auth.slice';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { createLogger } from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import globalSlice from './global.slice';
import staticContentSlice from 'modules/static-content/static-content.slice';
import coachManagementSlice from 'modules/coach-management/coach-management.slice';
import playerManagementSlice from 'modules/player-management/player-management.slice';
import dashboardSlice from 'modules/dashboard/dashboard.slice';
import teamManagementSlice from 'modules/team-management/team-management.slice';
import paymentLogsSlice from 'modules/payment-logs/payment-logs.slice';

const logger = createLogger({
  predicate: () => process.env.NODE_ENV === 'development',
  logErrors: true
});

const reducers = combineReducers({
  global: globalSlice,
  auth: authReducer,
  staticContent: staticContentSlice,
  coachManagement: coachManagementSlice,
  playerManagement: playerManagementSlice,
  paymentLogs: paymentLogsSlice,
  dashboard: dashboardSlice,
  teamManagement: teamManagementSlice
});

const persistConfig = {
  key: 'root',
  blacklist: [],
  storage: storage,
  whitelist: ['auth']
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [logger, thunk]
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export const useAppDispatch = () => useDispatch<typeof store.dispatch & ThunkDispatch<any, any, any>>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
