export const DRAWER_WIDTH = 260;
export const STATUSES: Array<{ label: string; value: number }> = [
  { label: 'DELETED', value: 0 },
  { label: 'ACTIVE', value: 1 },
  { label: 'BLOCKED', value: 2 }
];
export const RESEND_OTP_TIMER = 60;

export const DUPLICATE_MOBILE_NUMBERS = 'The file contains duplicate mobile numbers, please re-verify the file.';
export const DUPLICATE_EMAILS = 'The file contains duplicate emails, please re-verify the file.';
export const DUPLICATE_EMAILS_AND_MOBILE_NUMBERS =
  'The file contains duplicate emails and mobile numbers, please re-verify the file.';

export const SUPPORT_EMAIL = 'maxxxsupport@gmail.com';
