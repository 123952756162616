const ROUTES = {
  BASE_ROUTE: '/',

  AUTH_ROUTES: {
    LOGIN: '/',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password'
  },

  FEATURE_ROUTES: {
    DASHBOARD: '/dashboard',
    PLAYER_MANAGEMENT: {
      BASE_ROUTE: '/player-management',
      ALL_PLAYERS: {
        BASE_ROUTE: '/player-management/all-players',
        ALL_PLAYERS_LIST: '/player-management/all-players/list',
        PLAYER_DETAILS: '/player-management/all-players/details',
        ADD_PLAYER: '/player-management/all-players/add-player'
      },
      PENDING_PLAYERS: {
        BASE_ROUTE: '/player-management/pending-players',
        PENDING_PLAYERS_LIST: '/player-management/pending-players/list'
      }
    },
    COACH_MANAGEMENT: {
      BASE_ROUTE: '/coach-management',
      ALL_COACHES: {
        BASE_ROUTE: '/coach-management/all-coaches',
        ALL_COACHES_LIST: '/coach-management/all-coaches/list',
        COACH_DETAILS: '/coach-management/all-coaches/details',
        ADD_COACH: '/coach-management/all-coaches/add-coach'
      },
      PENDING_COACHES: {
        BASE_ROUTE: '/coach-management/pending-coaches',
        PENDING_COACHES_LIST: '/coach-management/pending-coaches/list'
      }
    },
    TEAM_MANAGEMENT: {
      BASE_ROUTE: '/team-management',
      TEAM_LISTING: '/team-management/listing',
      TEAM_DETAILS: '/team-management/details',
      ADD_TEAM: '/team-management/add',
      EDIT_TEAM: '/team-management/edit'
    },
    STATIC_CONTENT: {
      BASE_ROUTE: '/static-content',
      CONTACT_US: '/static-content/contact-us',
      PRIVACY_POLICY: '/static-content/privacy-policy',
      FAQ: '/static-content/faq',
      TERMS_AND_CONDITIONS: '/static-content/terms-and-conditions',
      ABOUT_US: '/static-content/about-us'
    },
    MY_PROFILE: '/profile',
    CHANGE_PASSWORD: '/change-password',
    PAYMENT_LOGS: '/payment-logs'
  }
};

export default ROUTES;
