import LazyLoader from 'components/routesContainer';
import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import ROUTES from './routes';
import PublicRoute from './publicRoute';
import AuthWrapper from 'layout/auth-wrapper';

const Login = LazyLoader(lazy(() => import('modules/auth/login')));
const ForgotPassword = LazyLoader(lazy(() => import('modules/auth/forgot-password')));
const ResetPassword = LazyLoader(lazy(() => import('modules/auth/reset-password')));

const AuthRoutes: RouteObject = {
  path: ROUTES.BASE_ROUTE,
  element: <Outlet />,
  children: [
    {
      path: ROUTES.AUTH_ROUTES.LOGIN,
      element: (
        <PublicRoute>
          <AuthWrapper>
            <Login />
          </AuthWrapper>
        </PublicRoute>
      )
    },
    {
      path: ROUTES.AUTH_ROUTES.FORGOT_PASSWORD,
      element: (
        <PublicRoute>
          <AuthWrapper>
            <ForgotPassword />
          </AuthWrapper>
        </PublicRoute>
      )
    },
    {
      path: `${ROUTES.AUTH_ROUTES.RESET_PASSWORD}/:token`,
      element: (
        <PublicRoute>
          <AuthWrapper>
            <ResetPassword />
          </AuthWrapper>
        </PublicRoute>
      )
    }
  ]
};
export default AuthRoutes;
