const styles = {
  mainContainer: {
    display: 'flex',
    width: '100%'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '50px 0'
  },
  cover: {
    width: '50%',
    height: '100vh',
    objectFit: 'cover'
  },
  childContainer: {
    margin: 'auto',
    width: '30%',
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  maxxxLogo: {
    margin: '24px 0 50px 0',
    maxWidth: '300px'
  }
};

export default styles;
