import NavigationScroll from 'layout/navigationScroll';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import PageLoader from 'components/routesContainer/pageLoader';
import { SUBSCRIPTION_STATUS } from 'enums/enums';
import { getPaymentLink } from 'store/global.slice';
import PurchaseSubscriptionModal from 'components/purchase-subscription-modal';

type Props = {
  children: any;
};

const VerifySubscription = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const { profileData } = useAppSelector((state: RootState) => state.auth);
  const handleGeneratePaymentLink = async () => {
    if (profileData.subscriptionPrice > 0 && profileData.subscriptionStatus !== SUBSCRIPTION_STATUS.ACTIVE) {
      const { data, statusCode } = await dispatch(getPaymentLink()).unwrap();
      if (statusCode === 200 && data) {
        window.open(data, '_self');
      }
    }
  };

  if (profileData.subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE) {
    return <NavigationScroll>{children}</NavigationScroll>;
  } else {
    return profileData && profileData.subscriptionStatus ? (
      <PurchaseSubscriptionModal
        subscriptionStatus={profileData.subscriptionStatus}
        isOpen={true}
        profileDetails={profileData}
        handleProceedForPayment={handleGeneratePaymentLink}
      ></PurchaseSubscriptionModal>
    ) : (
      <PageLoader />
    );
  }
};

export default VerifySubscription;
