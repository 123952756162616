import { Box } from '@mui/system';
import { Modal, Typography } from '@mui/material';

import ContainedButton from 'components/button/contained-button';
import { styles } from './styles';

type LogoutPopupType = {
  handleCancel: () => void;
  handleLogout: () => void;
};

export default function LogoutPopup({ handleCancel, handleLogout }: LogoutPopupType) {
  return (
    <Modal open={true} aria-labelledby="logout-modal-title" aria-describedby="logout-modal-description">
      <Box sx={styles.mainContainer}>
        <Box sx={styles.heading}>
          <Typography variant="h3" component="h3">
            {'Logout'}
          </Typography>
        </Box>
        <Box sx={styles.modal}>
          <Typography variant="h4" component="h4">
            {'Are you sure you want to Logout?'}
          </Typography>
          <Box sx={styles.buttonContainer}>
            <ContainedButton
              style={{ minWidth: '140px' }}
              variant={'outlined'}
              onClick={handleCancel}
              title={'Cancel'}
              fullWidth
            />
            <ContainedButton style={{ minWidth: '140px' }} title={'Logout'} onClick={handleLogout} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
