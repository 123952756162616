import { Dispatch } from 'react';
import axios, { AxiosResponse } from 'axios';

import { BASIC_AUTH_PASSWORD, BASIC_AUTH_USERNAME, FILE_UPLOAD } from 'constants/api-endpoints';
import { setLoading } from 'store/global.slice';
import { SUPPORT_EMAIL } from 'constants/constants';

export const convertDate = (date: string | number | Date): string => {
  // const monthNames = [
  //   'January',
  //   'February',
  //   'March',
  //   'April',
  //   'May',
  //   'June',
  //   'July',
  //   'August',
  //   'September',
  //   'October',
  //   'November',
  //   'December'
  // ];

  const resultDate = new Date(date);
  const dd = resultDate.getUTCDate();
  const mm = resultDate.getUTCMonth() + 1;
  if (dd && mm && resultDate) {
    return `${
      (dd < 10 ? '0' + dd : dd) +
      '/' +
      (mm < 10 ? '0' + mm : mm) +
      '/' +
      resultDate.getUTCFullYear().toString().substring(2)
    } `;
  } else return '';
};
export const convertStatus = (status: number) => {
  switch (status) {
    case 1:
      return 'ACTIVE';
    case 2:
      return 'BLOCKED';
    case 0:
      return 'DELETED';
    default:
  }
};

export const FontFamily = (
  fontWeight: number,
  fontSize: number | string,
  lineHeight: number | string,
  fontStyle = 'normal',
  fontFamily = `Poppins`
) => {
  let fontsize = '';
  let lineheight = '';

  if (typeof fontSize === 'string') fontsize = fontSize;
  else fontsize = `${fontSize}px`;

  if (typeof lineHeight === 'string') lineheight = lineHeight;
  else lineheight = `${lineHeight}px`;

  return `${fontStyle} ${fontWeight} ${fontsize}/${lineheight} ${fontFamily}`;
};

export const uploadFile = (file: any, callback?: any) => (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));
  const basicAuthToken = btoa(`${BASIC_AUTH_USERNAME}:${BASIC_AUTH_PASSWORD}`);
  const formdata = new FormData();
  formdata.append('file', file);

  const config = {
    method: 'post',
    url: process.env.REACT_APP_BASE_URL + FILE_UPLOAD,
    headers: {
      accept: 'application/json',
      basic: `Basic ${basicAuthToken}`
    },
    data: formdata
  };

  axios(config)
    .then((response: AxiosResponse<any>) => {
      dispatch(setLoading(false));
      const { data } = response.data;
      if (callback) callback(data);
    })
    .catch((err) => {
      console.log(err);
      dispatch(setLoading(false));
    });
};

export const titleCase = (word: string) => {
  if (!word) {
    return word;
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const convertNumberToAbbreviatedString = (value: number) => {
  let newValue: any = value;
  if (value >= 1000) {
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor(('' + value).length / 3);
    let shortValue: any = '';
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
};

export const upperCamelCase = (word: string) => {
  const words = word.split(' ');
  let result = '';

  words.forEach((word) => {
    result += word.charAt(0).toUpperCase() + word.toLowerCase().slice(1) + ' ';
  });
  return result;
};

export const getStatsDataFromArrayById = (data: Array<any>, key: string, dataKey: string) => {
  const statItem = data.find((item) => item._id === key);
  if (statItem) {
    return statItem[dataKey];
  } else {
    return 0;
  }
};

export const openSupportMail = () => {
  window.open(`mailto:${SUPPORT_EMAIL}`, '_blank');
};
