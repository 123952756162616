import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import ROUTES from './routes';
import Feature from 'layout/feature';
import PrivateRoute from './privateRoute';
import LazyLoader from 'components/routesContainer';

const Dashboard = LazyLoader(lazy(() => import('modules/dashboard')));
const StaticContent = LazyLoader(lazy(() => import('modules/static-content')));
const ContactUs = LazyLoader(lazy(() => import('modules/static-content/contact-us')));
const PrivacyPolicy = LazyLoader(lazy(() => import('modules/static-content/privacy-policy')));
const Faq = LazyLoader(lazy(() => import('modules/static-content/faq')));
const TermsAndCondition = LazyLoader(lazy(() => import('modules/static-content/terms-and-condition')));
const AboutUs = LazyLoader(lazy(() => import('modules/static-content/about-us')));
const PlayerManagement = LazyLoader(lazy(() => import('modules/player-management')));
const CoachManagement = LazyLoader(lazy(() => import('modules/coach-management')));
const MyProfile = LazyLoader(lazy(() => import('modules/auth/my-profile')));
const ChangePassword = LazyLoader(lazy(() => import('modules/auth/change-password')));
const AllCoaches = LazyLoader(lazy(() => import('modules/coach-management/all-coaches')));
const AllCoachList = LazyLoader(lazy(() => import('modules/coach-management/all-coaches/all-coach-list')));
const PendingCoaches = LazyLoader(lazy(() => import('modules/coach-management/pending-coaches')));
const CoachDetails = LazyLoader(lazy(() => import('modules/coach-management/all-coaches/coach-details')));
const AddCoach = LazyLoader(lazy(() => import('modules/coach-management/all-coaches/add-coach')));
const PendingCoachList = LazyLoader(lazy(() => import('modules/coach-management/pending-coaches/pending-coach-list')));
const AllPlayerList = LazyLoader(lazy(() => import('modules/player-management/all-players/all-players-list')));
const AllPlayers = LazyLoader(lazy(() => import('modules/player-management/all-players')));
const PlayerDetails = LazyLoader(lazy(() => import('modules/player-management/all-players/player-details')));
const AddPlayer = LazyLoader(lazy(() => import('modules/player-management/all-players/add-player')));
const PendingPlayers = LazyLoader(lazy(() => import('modules/player-management/pending-players')));
const PendingPlayerList = LazyLoader(
  lazy(() => import('modules/player-management/pending-players/pending-players-list'))
);
const TeamManagement = LazyLoader(lazy(() => import('modules/team-management')));
const TeamListing = LazyLoader(lazy(() => import('modules/team-management/team-listing')));
const TeamDetails = LazyLoader(lazy(() => import('modules/team-management/team-details')));
const AddTeam = LazyLoader(lazy(() => import('modules/team-management/add-team')));
const PaymentLogs = LazyLoader(lazy(() => import('modules/payment-logs')));

const FeatureRoutes: RouteObject = {
  path: ROUTES.BASE_ROUTE,
  element: <Feature />,
  children: [
    {
      path: ROUTES.FEATURE_ROUTES.DASHBOARD,
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      )
    },
    {
      path: ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.BASE_ROUTE,
      element: (
        <PrivateRoute>
          <PlayerManagement />
        </PrivateRoute>
      ),
      children: [
        {
          path: '',
          element: <Navigate to={ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.ALL_PLAYERS.BASE_ROUTE}></Navigate>
        },
        {
          path: ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.ALL_PLAYERS.BASE_ROUTE,
          element: <AllPlayers />,
          children: [
            {
              path: '',
              element: <Navigate to={ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.ALL_PLAYERS.ALL_PLAYERS_LIST} />
            },
            {
              path: `${ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.ALL_PLAYERS.ALL_PLAYERS_LIST}`,
              element: <AllPlayerList />
            },
            {
              path: `${ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.ALL_PLAYERS.PLAYER_DETAILS}/:id`,
              element: <PlayerDetails />
            },
            {
              path: `${ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.ALL_PLAYERS.ADD_PLAYER}`,
              element: <AddPlayer />
            }
          ]
        },
        {
          path: ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.PENDING_PLAYERS.BASE_ROUTE,
          element: <PendingPlayers />,
          children: [
            {
              path: '',
              element: <Navigate to={ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.PENDING_PLAYERS.PENDING_PLAYERS_LIST} />
            },
            {
              path: `${ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.PENDING_PLAYERS.PENDING_PLAYERS_LIST}`,
              element: <PendingPlayerList />
            }
          ]
        }
      ]
    },
    {
      path: ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.BASE_ROUTE,
      element: (
        <PrivateRoute>
          <CoachManagement />
        </PrivateRoute>
      ),
      children: [
        {
          path: '',
          element: <Navigate to={ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.ALL_COACHES.BASE_ROUTE}></Navigate>
        },
        {
          path: ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.ALL_COACHES.BASE_ROUTE,
          element: <AllCoaches />,
          children: [
            {
              path: '',
              element: <Navigate to={ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.ALL_COACHES.ALL_COACHES_LIST} />
            },
            {
              path: `${ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.ALL_COACHES.ALL_COACHES_LIST}`,
              element: <AllCoachList />
            },
            {
              path: `${ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.ALL_COACHES.COACH_DETAILS}/:id`,
              element: <CoachDetails />
            },
            {
              path: `${ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.ALL_COACHES.ADD_COACH}`,
              element: <AddCoach />
            }
          ]
        },
        {
          path: ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.PENDING_COACHES.BASE_ROUTE,
          element: <PendingCoaches />,
          children: [
            {
              path: '',
              element: <Navigate to={ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.PENDING_COACHES.PENDING_COACHES_LIST} />
            },
            {
              path: `${ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.PENDING_COACHES.PENDING_COACHES_LIST}`,
              element: <PendingCoachList />
            }
          ]
        }
      ]
    },
    {
      path: ROUTES.FEATURE_ROUTES.TEAM_MANAGEMENT.BASE_ROUTE,
      element: (
        <PrivateRoute>
          <TeamManagement />
        </PrivateRoute>
      ),
      children: [
        {
          path: '',
          element: <Navigate to={ROUTES.FEATURE_ROUTES.TEAM_MANAGEMENT.TEAM_LISTING} />
        },
        {
          path: ROUTES.FEATURE_ROUTES.TEAM_MANAGEMENT.TEAM_LISTING,
          element: <TeamListing />
        },
        {
          path: `${ROUTES.FEATURE_ROUTES.TEAM_MANAGEMENT.TEAM_DETAILS}/:id`,
          element: <TeamDetails />
        },
        {
          path: `${ROUTES.FEATURE_ROUTES.TEAM_MANAGEMENT.ADD_TEAM}`,
          element: <AddTeam />
        },
        {
          path: `${ROUTES.FEATURE_ROUTES.TEAM_MANAGEMENT.EDIT_TEAM}/:id`,
          element: <AddTeam />
        }
      ]
    },
    {
      path: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.BASE_ROUTE,
      element: (
        <PrivateRoute>
          <StaticContent />
        </PrivateRoute>
      ),

      children: [
        {
          path: '',
          element: <Navigate to={ROUTES.FEATURE_ROUTES.STATIC_CONTENT.CONTACT_US} />
        },
        { path: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.CONTACT_US, element: <ContactUs /> },
        { path: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.PRIVACY_POLICY, element: <PrivacyPolicy /> },
        { path: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.FAQ, element: <Faq /> },
        { path: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.TERMS_AND_CONDITIONS, element: <TermsAndCondition /> },
        { path: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.ABOUT_US, element: <AboutUs /> }
      ]
    },
    {
      path: ROUTES.FEATURE_ROUTES.MY_PROFILE,
      element: (
        <PrivateRoute>
          <MyProfile />
        </PrivateRoute>
      )
    },
    {
      path: ROUTES.FEATURE_ROUTES.CHANGE_PASSWORD,
      element: (
        <PrivateRoute>
          <ChangePassword />
        </PrivateRoute>
      )
    },
    {
      path: ROUTES.FEATURE_ROUTES.PAYMENT_LOGS,
      element: (
        <PrivateRoute>
          <PaymentLogs />
        </PrivateRoute>
      )
    }
  ]
};
export default FeatureRoutes;
