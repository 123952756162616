export enum STATUS {
  'ACTIVE' = 1,
  'BLOCKED' = 2,
  'DELETED' = 0
}
export enum CONTENT_TYPES {
  'CONTACT_US' = 'CONTACT_US',
  'PRIVACY_POLICY' = 'PRIVACY_POLICY',
  'TERMS_AND_CONDITIONS' = 'TERMS_AND_CONDITIONS',
  'FAQ' = 'FAQ',
  'ABOUT_US' = 'ABOUT_US'
}

export enum STATIC_CONTENT_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export enum INSTITUTE_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export enum COACH_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  DELETED = 'DELETED'
}
export enum PLAYER_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  DELETED = 'DELETED'
}

export enum INSTITUTE_TYPES {
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
  UNIVERSITY = 'UNIVERSITY',
  CLUB_SPORT = 'CLUB_SPORT',
  PROFESSIONAL = 'PROFESSIONAL'
}
export enum INSTITUTE_SUBSCRIPTION_TYPES {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY'
}
export enum SUBSCRIPTION_STATUS {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED'
}

export enum PAYMENT_STATUS {
  'PENDING' = 'PENDING',
  'FAILED' = 'FAILED',
  'CANCELLED' = 'CANCELLED',
  'SUCCESS' = 'SUCCESS',
  'EXPIRED' = 'EXPIRED'
}

export enum EXCEL_FILE_HEADERS {
  NAME = 'NAME',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  COUNTRY_CODE = 'COUNTRY_CODE',
  MOBILE_NUMBER = 'MOBILE_NUMBER'
}

export enum STATS_COUNT_KEYS {
  TOTAL_COUNT_KEY = 'total_count',
  ACTIVE_COUNT_KEY = 'active_count'
}

export enum ENTITY_TYPES {
  COACH = 'COACH',
  TEAM = 'Team',
  PLAYER = 'PLAYER'
}

export enum TEAM_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED'
}

export enum SPORTS_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export type subscriptionStatus = `${SUBSCRIPTION_STATUS}`;

export enum PROGRESS_VIEW_OPTIONS {
  CURRENT_PROGRESS = 'Current Progress',
  DATE_RANGE = 'Date Range'
}
