import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Content, ContentGetData, ContentType, Faq } from './static-content.interface';
import { ApiResponse } from 'interfaces/api.interface';
import { setLoading } from 'store/global.slice';
import { http } from 'utils/http.service';
import { RootState } from 'store';
import { GET_CONTENT } from 'constants/api-endpoints';
import { STATIC_CONTENT_STATUS } from 'enums/enums';

interface StaticContent {
  ABOUT_US: Content;
  CONTACT_US: Content;
  PRIVACY_POLICY: Content;
  TERMS_AND_CONDITIONS: Content;
  FAQ: Content;
}

export const staticContentSlice = createSlice({
  name: 'staticContent',
  initialState: {
    ABOUT_US: {},
    PRIVACY_POLICY: {},
    TERMS_AND_CONDITIONS: {},
    CONTACT_US: {},
    FAQ: {
      faqs: []
    }
  } as StaticContent,
  reducers: {
    setContent: (state, { payload }: { payload: Content & { type: ContentType } }) => {
      state[payload.type] = {
        ...payload
      };
    }
  },
  extraReducers(builder) {
    builder.addCase(getContent.fulfilled, (state, action) => {
      state[action.payload.type] = { ...action.payload.data };
    });
    builder.addCase(getFaqs.fulfilled, (state, action) => {
      state.FAQ.faqs = [...action.payload];
    });
  }
});
export const getContent = createAsyncThunk(
  'content/get',
  async (payload: { contentType: ContentType }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const res = await http.get<ApiResponse<Array<Content>>>(`${GET_CONTENT}?entityType=INSTITUTE`, payload);
      const { data } = res.data;
      if (data && data[0]) {
        return { data: data[0], type: payload.contentType };
      } else {
        return { data: {}, type: payload.contentType };
      }
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getFaqs = createAsyncThunk('faqs/get', async (payload: ContentGetData, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setLoading(true));
    const res = await http.get<ApiResponse<Array<Faq>>>(
      `${GET_CONTENT}?status=${STATIC_CONTENT_STATUS.ACTIVATED}`,
      payload
    );
    const { data: faqs } = res.data;
    if (faqs && faqs.length) {
      return faqs;
    } else {
      return [];
    }
  } catch (error) {
    return rejectWithValue(error);
  } finally {
    dispatch(setLoading(false));
  }
});
export const { setContent } = staticContentSlice.actions;
export const selectFaqs = (state: RootState) => state.staticContent.FAQ;
export const selectContent = (contentType: ContentType) => (state: RootState) => state.staticContent[contentType];

export default staticContentSlice.reducer;
