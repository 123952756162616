import {
  Box,
  Card,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';

// assets
import Transitions from '../transitions';
import LogoutPopup from './logoutPopup';
import AppAvatar from '../avatar/avatar';
import useProfileHelper from './profile.helper';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const {
    theme,
    open,
    showLogoutPopop,
    anchorRef,
    profileData,
    handleProfile,
    handleChangePassword,
    toggleLogoutPopup,
    handleToggle,
    handleClose,
    handleCancel,
    handleLogout
  } = useProfileHelper();

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          padding: '16px 10px 16px 10px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },

          '& .MuiChip-label': {
            lineHeight: 0,
            paddingRight: 0,
            fontSize: '15px',
            display: 'flex',
            alignItems: 'center',

            '& svg': {
              marginLeft: '10px'
            }
          },

          '& .MuiChip-icon': {
            marginRight: 0
          }
        }}
        icon={<AppAvatar name={profileData.name} url={profileData.imageUrl} />}
        label={
          <>
            {profileData.name}
            <SettingsOutlinedIcon sx={{ color: theme.palette.primary.main }} />
          </>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Card
                  sx={{
                    border: 'none',
                    borderColor: theme.palette.primary[200] + 25,
                    ':hover': {
                      boxShadow: theme.shadows[16]
                    }
                  }}
                  elevation={16}
                >
                  <Box sx={{ p: 2 }}>
                    <List
                      component="nav"
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '10px',

                        '& .MuiListItemButton-root': {
                          mt: 0.5
                        }
                      }}
                    >
                      <ListItemButton
                        sx={{
                          borderRadius: `12px`
                        }}
                        onClick={handleProfile}
                      >
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{'My Profile'}</Typography>} />
                      </ListItemButton>

                      <ListItemButton
                        sx={{
                          borderRadius: `12px`
                        }}
                        onClick={handleChangePassword}
                      >
                        <ListItemIcon>
                          <KeyIcon sx={{ transform: 'rotate(20deg)' }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{'Change Password'}</Typography>} />
                      </ListItemButton>

                      <ListItemButton
                        sx={{
                          borderRadius: `12px`
                        }}
                        onClick={toggleLogoutPopup}
                      >
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{'Logout'}</Typography>} />
                      </ListItemButton>
                    </List>
                  </Box>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      {showLogoutPopop && <LogoutPopup handleCancel={handleCancel} handleLogout={handleLogout} />}
    </>
  );
};

export default ProfileSection;
