import { Fragment } from 'react';
import { Box, useTheme } from '@mui/material';

import styles from './styles';
import Logo from 'assets/images/logo.svg';
import CoverImage from 'assets/images/authCoverImage.jpg';

const AuthWrapper = (props: any) => {
  const { children } = props;
  const theme = useTheme();
  return (
    <Fragment>
      <Box
        sx={{
          ...styles.mainContainer,
          background: theme.palette.secondary.light
        }}
      >
        <Box sx={{ ...styles.childContainer }}>
          <Box component="img" sx={styles.maxxxLogo} src={Logo} alt="Maxxx Logo" />
          {children}
        </Box>
        <Box component="img" sx={styles.cover} src={CoverImage} alt="Player Image" />
      </Box>
    </Fragment>
  );
};

export default AuthWrapper;
