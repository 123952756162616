import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CHANGE_PASSWORD, LOGOUT, PROFILE } from 'constants/api-endpoints';
import { RootState } from 'store';
import { setLoading } from 'store/global.slice';
import { http } from 'utils/http.service';
import { AuthModel, ChangePasswordType, UpdateProfile, UserModel } from './auth.model';
import { toastService } from 'utils/toast.service';
import { AxiosError } from 'axios';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    status: 'idle',
    error: '',
    profileData: {},
    addOnPlans: null
  } as AuthModel,

  reducers: {
    setUser: (state, { payload }: { payload: UserModel }) => {
      state.user = {
        ...payload
      };
    },

    setToken: (state, { payload }: { payload: string }) => {
      state.token = payload;
    }
  },

  extraReducers(builder) {
    builder
      .addCase(getProfile.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.profileData = action?.payload?.data;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message as string;
        const error = action.payload as AxiosError;
        if (error.response?.status == 401) {
          localStorage.clear();
          location.reload();
        }
      });
  }
});

export const getProfile = createAsyncThunk('myProfile/getProfile', async (_, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setLoading(true));
    const response = await http.get(PROFILE);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  } finally {
    dispatch(setLoading(false));
  }
});

export const updateProfile = createAsyncThunk(
  'myProfile/updateProfile',
  async ({ values }: UpdateProfile, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.put(PROFILE, values);
      const { message, success } = response.data;
      if (success === true) {
        toastService.showToast(message, 'success');
      }
      return success;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const logout = createAsyncThunk('logout', async (callback: () => void, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setLoading(true));
    dispatch;
    const response = await http.post(LOGOUT, {});
    const { success } = response.data;
    if (success) {
      callback();
    }
  } catch (error) {
    return rejectWithValue(error);
  } finally {
    dispatch(setLoading(false));
  }
});

export const changePassword = createAsyncThunk(
  'myProfile/changePassword',
  async ({ dataTosend, successCallback }: ChangePasswordType, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.put(CHANGE_PASSWORD, dataTosend);
      const { success } = response.data;
      if (success === true) successCallback();
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const { setUser } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
