import React from 'react';
import { StyledEngineProvider, CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//default theme
import themes from './themes';
import { store } from './store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { RouteManager } from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Loader from 'components/loader';
import { LocalizationProvider } from '@mui/x-date-pickers';

const themeCustomization = {
  opened: true,
  borderRadius: 6,
  defaultId: 'default',
  fontFamily: `Poppins`,
  isOpen: [] // for active default menu
};

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(themeCustomization)}>
        <CssBaseline />
        <Provider store={store}>
          <Loader />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <RouteManager />
            </BrowserRouter>
          </LocalizationProvider>
          <ToastContainer />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
