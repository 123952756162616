import { Avatar } from '@mui/material';

const AppAvatar = ({ name, url }: { name: string; url: string }) => {
  const getName = (name: string) => {
    const nameArray = name.split(' ');
    if (nameArray.length === 1) return `${nameArray[0][0]}`;
    else if (nameArray.length === 2) return `${nameArray[0][0]}${nameArray[1][0]}`;
    else if (nameArray.length === 3 || nameArray.length > 3)
      return `${nameArray[0][0]}${nameArray[1][0]}${nameArray[2][0]}`;
    else return 'I';
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        color: '#fff'
      },
      children: getName(name)
    };
  }
  return <Avatar src={url ? url : ''} {...stringAvatar(name ? name : 'Institute')} />;
};

export default AppAvatar;
