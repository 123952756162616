import { Navigate, Outlet } from 'react-router-dom';
import { AppBar, Box, CssBaseline, Toolbar, useTheme, styled } from '@mui/material';

//custom imports
import { DRAWER_WIDTH } from 'constants/constants';
import Header from 'components/header';
import Sidebar from './sidebar';
import VerifySubscription from 'components/verify-subscription';
import { useAuth } from 'hooks/auth/useAuth';
import ROUTES from 'routes/routes';

//component imports

const Main = styled('main')(({ theme }: any) => ({
  ...theme.typography.mainContent,
  ...{
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const MainLayout = () => {
  const theme = useTheme();

  const renderAppBar = () => {
    return (
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{ bgcolor: theme.palette.background.default }}
      >
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>
    );
  };

  return useAuth() ? (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <CssBaseline />
      {renderAppBar()}
      {/* drawer */}

      <VerifySubscription>
        <Sidebar />
        <Main theme={theme}>
          <Outlet />
        </Main>
      </VerifySubscription>
    </Box>
  ) : (
    <Navigate to={ROUTES.AUTH_ROUTES.LOGIN} />
  );
};

export default MainLayout;
