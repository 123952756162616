import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiState, PaginatedQuery } from 'interfaces/api.interface';
import { PaymentLog } from './payment-logs.interface';
import { setLoading } from 'store/global.slice';
import { http } from 'utils/http.service';
import { FormUtils } from 'utils/form.utils';
import { PAYMENT_LOGS_GET } from 'constants/api-endpoints';
import { RootState } from 'store';

interface PaymentLogSlice {
  paymentLogs: Array<PaymentLog>;
  status: ApiState;
  error: string | null;
  total: number;
}

export const paymentLogsSlice = createSlice({
  name: 'paymentLogs',
  initialState: {
    paymentLogs: [],
    error: null,
    status: 'idle',
    total: 0
  } as PaymentLogSlice,

  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPaymentLogs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaymentLogs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { data: paymentLogs } = action.payload;
        state.paymentLogs = (paymentLogs.data && [...paymentLogs.data]) || [];
        state.total = paymentLogs.total ?? 0;
      })
      .addCase(getPaymentLogs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message as string;
      });
  }
});

export const getPaymentLogs = createAsyncThunk(
  'paymentLogs/list',
  async (query: PaginatedQuery, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...query, ...query.filter };
      delete newQuery.filter;
      const response = await http.get(PAYMENT_LOGS_GET, FormUtils.parse(newQuery));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export default paymentLogsSlice.reducer;

export const selectPaymentLogs = (state: RootState) => state.paymentLogs;
