import { Box } from '@mui/system';
import Logo from 'assets/images/logo.svg';

const LogoSection = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box>
        <img src={Logo} alt="Maxxx Logo" style={{ height: '40px' }} />
      </Box>
    </Box>
  );
};

export default LogoSection;
