import React, { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import AuthRoutes from './authRoutes';
import FeatureRoutes from './featureRoutes';
import LazyLoader from 'components/routesContainer';
const NotFound = LazyLoader(lazy(() => import('components/not-found')));
const NotFoundRoute: RouteObject = {
  path: '*',
  element: <NotFound />
};
export const RouteManager = () => {
  return useRoutes([AuthRoutes, FeatureRoutes, NotFoundRoute]);
};
