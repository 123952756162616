import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ADD_PLAYER_POST,
  GET_CATEGORIES,
  PLAYER_DELETE,
  PLAYER_DETAILS_GET,
  PLAYER_LIST_GET,
  PLAYER_RESEND_ACTIVATION_CODE,
  PLAYER_TEAM_LIST_GET,
  PLAYER_UPDATE_STATUS_PUT,
  PROGRESS_GET,
  PROGRESS_LIST_GET,
  UNVERIFIED_PLAYER_LIST_GET
} from 'constants/api-endpoints';
import { ApiResponse, ApiState, PaginatedQuery, PaginatedResponse } from 'interfaces/api.interface';
import { RootState } from 'store';
import { setLoading } from 'store/global.slice';
import { FormUtils } from 'utils/form.utils';
import { http } from 'utils/http.service';
import { toastService } from 'utils/toast.service';
import { Player, PlayerBasicInfo } from './player-management.interface';
import { PLAYER_STATUS } from 'enums/enums';
import { PlayerData } from './all-players/add-player/add-player.validator';
import { Team } from 'modules/team-management/team-management.interaface';
import { ParentCategory, ProgressList, ProgressStats } from 'interfaces/progress';

interface PlayerManagement {
  allPlayers: Array<Player>;
  selectedPlayer: Player | null;
  unverifiedPlayers: Array<PlayerBasicInfo>;
  status: ApiState;
  error: string | null;
  total: number;
  details: Player | null;
  playerTeams: {
    teams: Array<Team>;
    status: ApiState;
    error: string | null;
    total: number;
  };
  playerStats: ProgressStats | null;
  progressList: {
    total: number;
    status: ApiState;
    error: string | null;
    data: Array<ProgressList> | null;
    categories: ParentCategory[];
  };
}

export const PlayerManagementSlice = createSlice({
  name: 'PlayerManagement',
  initialState: {
    allPlayers: [],
    unverifiedPlayers: [],
    selectedPlayer: null,
    total: 0,
    error: null,
    status: 'idle',
    details: null,
    playerTeams: {
      teams: [],
      total: 0,
      error: null,
      status: 'idle'
    },
    playerStats: null,
    progressList: {
      categories: [],
      total: 0,
      status: 'idle',
      error: null,
      data: []
    }
  } as PlayerManagement,
  reducers: {
    setDetails: (state, { payload }: { payload: Player | null }) => {
      state.details = payload;
    },
    setPlayerStats: (state, { payload }: { payload: ProgressStats | null }) => {
      state.playerStats = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllPlayers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllPlayers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.allPlayers = [...action.payload.data.data])
          : (state.allPlayers = []);

        action.payload.data && action.payload.data.total
          ? (state.total = action.payload?.data?.total)
          : (state.total = 0);
      })
      .addCase(getAllPlayers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message as string;
      })
      .addCase(getUnverifiedPlayers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUnverifiedPlayers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.unverifiedPlayers = [...action.payload.data.data])
          : (state.unverifiedPlayers = []);

        action.payload.data && action.payload.data.total
          ? (state.total = action.payload?.data?.total)
          : (state.total = 0);
      })
      .addCase(getUnverifiedPlayers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message as string;
      })
      .addCase(getPlayerDetail.fulfilled, (state, action) => {
        state.details = action.payload.data;
      })
      .addCase(getPlayerTeams.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPlayerTeams.fulfilled, (state, action) => {
        state.playerTeams.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.playerTeams.teams = [...action.payload.data.data])
          : (state.playerTeams.teams = []);

        action.payload.data && action.payload.data.total
          ? (state.playerTeams.total = action.payload?.data?.total)
          : (state.playerTeams.total = 0);
      })
      .addCase(getPlayerTeams.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message as string;
      })
      .addCase(getPlayerProgressStats.fulfilled, (state, action) => {
        state.playerStats = action.payload.data;
      })
      .addCase(getPlayerProgressList.pending, (state) => {
        state.progressList.status = 'loading';
      })
      .addCase(getPlayerProgressList.fulfilled, (state, action) => {
        state.progressList.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.progressList.data = [...action.payload.data.data])
          : (state.progressList.data = []);

        action.payload.data && action.payload.data.total
          ? (state.progressList.total = action.payload?.data?.total)
          : (state.progressList.total = 0);
      })
      .addCase(getPlayerProgressList.rejected, (state, action) => {
        state.progressList.status = 'failed';
        state.progressList.error = action.error.message as string;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.data.length
          ? (state.progressList.categories = [...action.payload.data])
          : (state.progressList.categories = []);
      });
  }
});

export const getAllPlayers = createAsyncThunk(
  'player/list',
  async (query: PaginatedQuery, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...query, ...query.filter };
      delete newQuery.filter;
      const response = await http.get<ApiResponse<PaginatedResponse<Player>>>(
        PLAYER_LIST_GET,
        FormUtils.parse(newQuery)
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getUnverifiedPlayers = createAsyncThunk(
  'player/unverified-player-list',
  async (query: PaginatedQuery, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...query, ...query.filter };
      delete newQuery.filter;
      const response = await http.get<ApiResponse<PaginatedResponse<PlayerBasicInfo>>>(
        UNVERIFIED_PLAYER_LIST_GET,
        FormUtils.parse(newQuery)
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getPlayerDetail = createAsyncThunk(
  'player/details',
  async (query: { id: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.get<ApiResponse<Player>>(PLAYER_DETAILS_GET(query.id));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getPlayerTeams = createAsyncThunk(
  'player/teams',
  async (query: { playerId: string; pageOptions: PaginatedQuery }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.get<ApiResponse<PaginatedResponse<Team>>>(
        PLAYER_TEAM_LIST_GET(query.playerId),
        FormUtils.parse(query.pageOptions)
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updatePlayerStatus = createAsyncThunk(
  'player/updateStatus',
  async (payload: { id: string; status: PLAYER_STATUS }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.put<ApiResponse<any>>(PLAYER_UPDATE_STATUS_PUT(payload.id), {
        status: payload.status
      });
      toastService.showToast(response.data.message, 'success');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addPlayer = createAsyncThunk(
  'player/add-player',
  async (payload: { players: PlayerData[] }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.post<ApiResponse<any>>(ADD_PLAYER_POST, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const sendCodeToPlayer = createAsyncThunk(
  'player/send-activation-code',
  async (payload: { email: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.post<ApiResponse<any>>(PLAYER_RESEND_ACTIVATION_CODE, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const removePlayer = createAsyncThunk(
  'player/remove-player',
  async (payload: { id: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.delete<ApiResponse<any>>(PLAYER_DELETE(payload.id));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getPlayerProgressStats = createAsyncThunk(
  'player/progress-stats',
  async (query: { playerId: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.get<ApiResponse<ProgressStats>>(PROGRESS_GET(query.playerId));
      if (!response?.data?.data?.averageRating || response?.data?.data?.averageRating < 0) {
        response.data.data['averageRating'] = 0;
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getPlayerProgressList = createAsyncThunk(
  'player/progress-list',
  async (payload: { playerId: string; query: PaginatedQuery }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...payload.query, ...payload.query.filter };
      delete newQuery.filter;
      const response = await http.get<ApiResponse<PaginatedResponse<any>>>(
        PROGRESS_LIST_GET(payload.playerId),
        FormUtils.parse(newQuery)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getCategories = createAsyncThunk(
  'player-management/progress-list-categories',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.get<ApiResponse<Array<ParentCategory>>>(GET_CATEGORIES);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const selectPlayers = (state: RootState) => state.playerManagement;

export const { setDetails, setPlayerStats } = PlayerManagementSlice.actions;

export default PlayerManagementSlice.reducer;
