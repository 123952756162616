import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import React, { Fragment, forwardRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, Link } from 'react-router-dom';
import { SIDE_BAR_ITEMS, SidebarItem } from './sidebar.constants';
import { DRAWER_WIDTH } from 'constants/constants';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const Sidebar = () => {
  const theme = useTheme();
  const location = useLocation();

  const RenderSidebarItemWithSubmenu = (item: SidebarItem) => {
    const isSelected = location.pathname.indexOf(item.url) > -1;
    const [open, setOpen] = useState(false);

    const handleListItemWithSubmenu = () => {
      setOpen(!open);
    };
    return (
      <Fragment>
        <ListItemButton
          sx={{
            mb: 0.5,
            py: '8px',
            pl: '24px',
            borderRadius: '12px',
            alignItems: 'center',
            backgroundColor: 'inherit'
          }}
          selected={isSelected}
          onClick={handleListItemWithSubmenu}
        >
          <ListItemIcon sx={{ my: 'auto', minWidth: 36 }}>
            <img
              src={item.imgURL}
              height={24}
              width={24}
              alt={'side-bar-icon'}
              className={isSelected ? 'secondary-main-tint' : ''}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant={isSelected ? 'h5' : 'body1'}
                sx={(theme) => (isSelected ? { color: theme.palette.primary.main } : {})}
                color="inherit"
              >
                {item.title}
              </Typography>
            }
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" sx={{ paddingTop: 0 }}>
            {item.subMenu &&
              item.subMenu.map((subMenu) => {
                const listItemProps = {
                  component: forwardRef((props, ref: React.Ref<HTMLAnchorElement>) => (
                    <Link ref={ref} {...props} to={subMenu.url} target={'_self'} />
                  ))
                };
                const subMenuSelected = location.pathname.indexOf(subMenu.url) > -1;
                return (
                  <Fragment key={subMenu.id}>
                    <ListItemButton
                      sx={{
                        mb: 0.5,
                        py: '8px',
                        pl: '24px',
                        borderRadius: '12px',
                        alignItems: 'flex-start',
                        backgroundColor: 'inherit'
                      }}
                      selected={subMenuSelected}
                      {...listItemProps}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            sx={{ paddingLeft: '36px' }}
                            variant={subMenuSelected ? 'h5' : 'body1'}
                            color="inherit"
                          >
                            {subMenu.title}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <Divider sx={{ mt: 0.25, mb: 0.25 }} />
                  </Fragment>
                );
              })}
          </List>
        </Collapse>
        {!open ? <Divider sx={{ mt: 0.25, mb: 0.25 }} /> : null}
      </Fragment>
    );
  };

  const renderSidebarItem = (item: SidebarItem) => {
    const listItemProps = {
      component: forwardRef((props, ref: React.Ref<HTMLAnchorElement>) => (
        <Link ref={ref} {...props} to={item.url} target={'_self'} />
      ))
    };
    const isSelected = location.pathname.indexOf(item.url) > -1;

    return (
      <Fragment>
        <ListItemButton
          sx={{
            mb: 0.5,
            py: '8px',
            pl: '24px',
            borderRadius: '12px',
            alignItems: 'flex-start',
            backgroundColor: 'inherit'
          }}
          selected={isSelected}
          {...listItemProps}
        >
          <ListItemIcon sx={{ my: 'auto', minWidth: 36 }}>
            <img
              src={item.imgURL}
              height={24}
              width={24}
              alt={'side-bar-icon'}
              className={isSelected ? 'secondary-main-tint' : ''}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant={isSelected ? 'h5' : 'body1'}
                color="inherit"
                sx={(theme) => (isSelected ? { color: theme.palette.primary.main } : {})}
              >
                {item.title}
              </Typography>
            }
          />
        </ListItemButton>
        <Divider sx={{ mt: 0.25, mb: 0.25 }} />
      </Fragment>
    );
  };

  const drawer = (
    <PerfectScrollbar
      component="div"
      style={{
        paddingLeft: '16px',
        paddingRight: '16px',
        height: 'calc(100vh - 88px)'
      }}
    >
      <List sx={{ paddingTop: 0 }}>
        {SIDE_BAR_ITEMS.map((item: SidebarItem) => {
          return (
            <Fragment key={item.id}>
              {item.subMenu && item.subMenu.length ? RenderSidebarItemWithSubmenu(item) : renderSidebarItem(item)}
            </Fragment>
          );
        })}
      </List>
    </PerfectScrollbar>
  );

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: DRAWER_WIDTH }} aria-label="mailbox folders">
      <Drawer
        open={true}
        anchor={'left'}
        variant={'persistent'}
        sx={{
          '& .MuiDrawer-paper': {
            top: '88px',
            width: DRAWER_WIDTH,
            borderRight: 'none',
            color: theme.palette.text.primary,
            background: theme.palette.background.default
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
