import ROUTES from 'routes/routes';
import DASHBOARD_ICON from 'assets/images/dashboard.svg';
import PLAYER_ICON from 'assets/images/player.svg';
import COACH_ICON from 'assets/images/coach.svg';
import TEAM_ICON from 'assets/images/team.svg';
import CONTENT_ICON from 'assets/images/content.svg';
import PAYMENT_ICON from 'assets/images/payment.svg';

export interface SidebarItem {
  id: string;
  title: string;
  imgURL?: string;
  url: string;
  subMenu?: Array<{ title: string; url: string; id: string }>;
}

export const SIDE_BAR_ITEMS: SidebarItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    imgURL: DASHBOARD_ICON,
    url: ROUTES.FEATURE_ROUTES.DASHBOARD
  },
  {
    id: 'playerManagement',
    title: 'Player Management',
    imgURL: PLAYER_ICON,
    url: ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.BASE_ROUTE,
    subMenu: [
      {
        id: 'allPlayers',
        title: 'All Players',
        url: ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.ALL_PLAYERS.BASE_ROUTE
      },
      {
        id: 'pendingPlayers',
        title: 'Pending Players',
        url: ROUTES.FEATURE_ROUTES.PLAYER_MANAGEMENT.PENDING_PLAYERS.BASE_ROUTE
      }
    ]
  },
  {
    id: 'coachManagement',
    title: 'Coach Management',
    imgURL: COACH_ICON,
    url: ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.BASE_ROUTE,
    subMenu: [
      {
        id: 'allCoaches',
        title: 'All Coaches',
        url: ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.ALL_COACHES.BASE_ROUTE
      },
      {
        id: 'pendingCoaches',
        title: 'Pending Coaches',
        url: ROUTES.FEATURE_ROUTES.COACH_MANAGEMENT.PENDING_COACHES.BASE_ROUTE
      }
    ]
  },
  {
    id: 'teamManagement',
    title: 'Team Management',
    imgURL: TEAM_ICON,
    url: ROUTES.FEATURE_ROUTES.TEAM_MANAGEMENT.BASE_ROUTE
  },
  {
    id: 'contentManagement',
    title: 'Static Content',
    imgURL: CONTENT_ICON,
    url: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.BASE_ROUTE,
    subMenu: [
      { id: 'contactUs', title: 'Contact Us', url: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.CONTACT_US },
      { id: 'privacyPolicy', title: 'Privacy Policy', url: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.PRIVACY_POLICY },
      { id: 'faq', title: 'FAQ', url: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.FAQ },
      {
        id: 'termsAndCondition',
        title: 'Terms & Conditions',
        url: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.TERMS_AND_CONDITIONS
      },
      { id: 'aboutUs', title: 'About Us', url: ROUTES.FEATURE_ROUTES.STATIC_CONTENT.ABOUT_US }
    ]
  },
  {
    id: 'paymentLogs',
    title: 'Payment Logs',
    imgURL: PAYMENT_ICON,
    url: ROUTES.FEATURE_ROUTES.PAYMENT_LOGS
  }
];
