import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiResponse, ApiState, PaginatedQuery, PaginatedResponse } from 'interfaces/api.interface';
import { RootState } from 'store';
import { http } from 'utils/http.service';
import {
  ADD_TEAM_POST,
  COACH_LIST_GET,
  GET_PLAYERS_BY_TEAM,
  SPORT_LIST_GET,
  TEAM_DELETE,
  TEAM_DETAILS_GET,
  TEAM_LIST_GET,
  TEAM_UPDATE_PUT,
  TEAM_UPDATE_STATUS_PUT
} from 'constants/api-endpoints';
import { setLoading } from 'store/global.slice';
import { toastService } from 'utils/toast.service';
import { FormUtils } from 'utils/form.utils';
import { Sport, Team, TeamStatus } from './team-management.interaface';
import { TeamData } from './add-team/add-team.validator';
import { Coach } from 'modules/coach-management/coach-management.interface';
import { Player } from 'modules/player-management/player-management.interface';

interface TeamManagement {
  teams: Array<Team>;
  selectedTeam: Team | null;
  coachList: Array<Coach>;
  sportList: Array<Sport>;
  total: number;
  status: ApiState;
  error: string | null;
  teamPlayers: {
    players: Array<Player>;
    status: ApiState;
    error: string | null;
    total: number;
  };
}

export const teamManagementSlice = createSlice({
  name: 'teamManagement',
  initialState: {
    teams: [],
    selectedTeam: null,
    coachList: [],
    sportList: [],
    total: 0,
    error: null,
    status: 'idle',
    teamPlayers: {
      players: [],
      total: 0,
      error: null,
      status: 'idle'
    }
  } as TeamManagement,
  reducers: {
    setSelectedTeam: (state, { payload }: { payload: Team | null }) => {
      state.selectedTeam = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getTeams.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getTeams.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.data && action.payload.data ? (state.teams = [...action.payload.data.data]) : (state.teams = []);

        action.payload.data && action.payload.data ? (state.total = action.payload?.data?.total) : (state.total = 0);
      })
      .addCase(getTeams.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message as string;
      })
      .addCase(getTeamById.fulfilled, (state, action) => {
        state.selectedTeam = action.payload ? action?.payload.data : null;
      })
      .addCase(getSportList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.sportList = [...action.payload.data.data])
          : (state.sportList = []);
      })
      .addCase(getCoachList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.coachList = [...action.payload.data.data])
          : (state.coachList = []);
      })
      .addCase(getTeamPlayers.pending, (state) => {
        state.teamPlayers.status = 'loading';
      })
      .addCase(getTeamPlayers.fulfilled, (state, action) => {
        state.teamPlayers.status = 'succeeded';
        action.payload.data && action.payload.data.data
          ? (state.teamPlayers.players = [...action.payload.data.data])
          : (state.teamPlayers.players = []);

        action.payload.data && action.payload.data.total
          ? (state.teamPlayers.total = action.payload?.data?.total)
          : (state.teamPlayers.total = 0);
      })
      .addCase(getTeamPlayers.rejected, (state, action) => {
        state.teamPlayers.status = 'failed';
        state.teamPlayers.error = action.error.message as string;
      });
  }
});

export const getTeamPlayers = createAsyncThunk(
  'teams/getPlayers',
  async (query: { teamId: string; pageOptions: PaginatedQuery }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.get<ApiResponse<PaginatedResponse<Player>>>(
        GET_PLAYERS_BY_TEAM(query.teamId),
        query.pageOptions
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateTeamStatus = createAsyncThunk(
  'team-management/update-status',
  async (payload: { id: string; status: TeamStatus }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.patch<ApiResponse<string>>(TEAM_UPDATE_STATUS_PUT(payload.id), {
        status: payload.status
      });
      toastService.showToast(response.data.message, 'success');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getTeams = createAsyncThunk(
  'team-management/get-teams',
  async (query: PaginatedQuery, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...query, ...query.filter };
      delete newQuery.filter;

      const response = await http.get<ApiResponse<PaginatedResponse<Team>>>(TEAM_LIST_GET, FormUtils.parse(newQuery));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getTeamById = createAsyncThunk(
  'team-management/get-team-by-id',
  async (payload: { id: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.get<ApiResponse<Team>>(TEAM_DETAILS_GET(payload.id));
      return response.data ? response.data : null;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addTeam = createAsyncThunk(
  'team-management/add-team',
  async (payload: TeamData, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.post<ApiResponse<any>>(ADD_TEAM_POST, payload);
      return response.data ? response.data : null;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editTeam = createAsyncThunk(
  'team-management/edit-team',
  async (payload: { data: TeamData; id: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.put<ApiResponse<any>>(TEAM_UPDATE_PUT(payload.id), payload.data);
      return response.data ? response.data : null;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getCoachList = createAsyncThunk(
  'team-management/get-coach-list',
  async (query: PaginatedQuery, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...query, ...query.filter };
      delete newQuery.filter;
      const response = await http.get<ApiResponse<PaginatedResponse<Coach>>>(COACH_LIST_GET, FormUtils.parse(newQuery));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getSportList = createAsyncThunk(
  'team-management/get-sport-list',
  async (query: PaginatedQuery, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...query, ...query.filter };
      delete newQuery.filter;
      const response = await http.get<ApiResponse<PaginatedResponse<Sport>>>(SPORT_LIST_GET, FormUtils.parse(newQuery));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getAllCoaches = createAsyncThunk(
  'team-management/activated-coach-list',
  async (query: PaginatedQuery, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const newQuery = { ...query, ...query.filter };
      delete newQuery.filter;
      const response = await http.get<ApiResponse<PaginatedResponse<Coach>>>(COACH_LIST_GET, FormUtils.parse(newQuery));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const deleteTeam = createAsyncThunk(
  'team/remove-team',
  async (payload: { id: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await http.delete<ApiResponse<any>>(TEAM_DELETE(payload.id));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const { setSelectedTeam } = teamManagementSlice.actions;
export const selectTeamManagement = (state: RootState) => state.teamManagement;

export default teamManagementSlice.reducer;
