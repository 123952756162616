import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DASHBOARD_STATS_GET } from 'constants/api-endpoints';
import { ApiResponse, ApiState } from 'interfaces/api.interface';
import { RootState } from 'store';
import { setLoading } from 'store/global.slice';
import { http } from 'utils/http.service';

interface Dashboard {
  data: Array<any>;
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    data: []
  } as Dashboard,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getDashboardStats.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  }
});

export const getDashboardStats = createAsyncThunk('dashboard/stats', async (_, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setLoading(true));
    const res = await http.get<ApiResponse<Array<any>>>(DASHBOARD_STATS_GET);

    const { data } = res.data;
    return data;
  } catch (error) {
    return rejectWithValue(error);
  } finally {
    dispatch(setLoading(false));
  }
});

export const selectDashboard = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
