import { Button, ButtonProps } from '@mui/material';

interface Props {
  title: string;
  externalStyles?: object;
  primary?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
}

const ContainedButton = ({ title, externalStyles, variant = 'contained', ...otherProps }: Props & ButtonProps) => {
  return (
    <Button variant={variant} sx={{ ...externalStyles }} {...otherProps}>
      {title}
    </Button>
  );
};

export default ContainedButton;
