import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ROUTES from 'routes/routes';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { logout } from 'modules/auth/auth.slice';
import { getProfile } from 'modules/auth/auth.slice';
import { useAuth } from 'hooks/auth/useAuth';

const useProfileHelper = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const theme: any = useTheme();

  const [open, setOpen] = useState(false);
  const [showLogoutPopop, setShowLogoutPopup] = useState(false);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef: any = useRef(null);
  const { profileData } = useAppSelector((state: RootState) => state.auth);

  const handleProfile = (event: any) => {
    handleClose(event);
    navigate(ROUTES.FEATURE_ROUTES.MY_PROFILE);
  };

  const handleChangePassword = (event: any) => {
    handleClose(event);
    navigate(ROUTES.FEATURE_ROUTES.CHANGE_PASSWORD);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const toggleLogoutPopup = () => {
    handleToggle();
    setShowLogoutPopup(true);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      //   anchorRef?.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleLogout = async () => {
    dispatch(
      logout(() => {
        localStorage.clear();
        window.location.reload();
      })
    );
  };

  const handleCancel = () => {
    setShowLogoutPopup(false);
  };

  useEffect(() => {
    if (useAuth()) {
      dispatch(getProfile());
    } else {
      localStorage.clear();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    theme,
    dispatch,
    navigate,
    open,
    showLogoutPopop,
    setShowLogoutPopup,
    anchorRef,
    profileData,
    handleProfile,
    handleChangePassword,
    toggleLogoutPopup,
    handleToggle,
    handleClose,
    handleLogout,
    handleCancel
  };
};

export default useProfileHelper;
