import { DateValidationError } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export const FormUtils = {
  parse(value: any): any {
    const newValueInstance = Object.assign({}, value);
    (function isEmpty(data: any): boolean {
      if (typeof data === 'object' && data !== null) {
        if (Array.isArray(data)) {
          data.forEach((item: any, index: number) => {
            if (isEmpty(item) || item === null) {
              data.splice(index, 1);
            }
          });
        } else {
          const keys = Object.keys(data);
          keys.forEach((key) => {
            if (isEmpty(data[key])) {
              delete data[key];
            }
          });
        }
      }
      return data === null || data === undefined || data === '';
    })(newValueInstance);
    return newValueInstance;
  },

  parseMobileNumber: (value: string) =>
    value.replace('(', '').replace(')', '').replace('-', '').replace('+1', '').replace(/\s/g, '').trim(),

  formatMobileNumber: (countryCode: string, value: string) => {
    if (countryCode && value) {
      return countryCode + ' ' + '(' + value.slice(0, 3) + ')' + ' ' + value.slice(3, 6) + '-' + value.slice(6, 10);
    } else {
      return '-';
    }
  },

  formatDayjsDate: (date: dayjs.Dayjs) => date.format('MM/DD/YYYY'),

  handleDateError: (errorField: string, error: DateValidationError, maxDate: dayjs.Dayjs) => {
    let errorString = '';
    switch (error) {
      case 'maxDate':
        errorString = `${errorField} cannot be more than ${maxDate.format('MM/DD/YYYY')}`;
        break;
      default:
        break;
    }
    return errorString;
  }
};
