// material-ui
import { Box } from '@mui/material';

// project imports
import LogoSection from './logo/index';
import ProfileSection from './profile/index';

function Header() {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          padding: '16px 0px',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            width: 228,
            display: 'flex',
            paddingTop: '5px'
          }}
        >
          <LogoSection />
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        <ProfileSection />
      </Box>
    </>
  );
}

export default Header;
