import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { GET_PAYMENT_LINK } from 'constants/api-endpoints';
import { ApiResponse } from 'interfaces/api.interface';
import { RootState } from 'store';
import { http } from 'utils/http.service';

type GlobalState = {
  loading: boolean;
};

const initialState: GlobalState = {
  loading: false
};

const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setLoading: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  }
});

export const getPaymentLink = createAsyncThunk('institute/payment', async (_, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setLoading(true));
    const response = await http.get<ApiResponse<any>>(GET_PAYMENT_LINK);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  } finally {
    dispatch(setLoading(false));
  }
});
// Action creators are generated for each case reducer function
export const { setLoading } = globalSlice.actions;

export const selectLoading = (state: RootState) => state.global.loading;
export default globalSlice.reducer;
